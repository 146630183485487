import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { default as Box, default as Grid } from '@material-ui/core/Grid';
import MobileStepper from '@material-ui/core/MobileStepper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Optimize from '@material-ui/icons/TimelineRounded';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import CButton from 'components/CButton';
import ConfirmationDialog from 'components/ConfirmationDialog';
import GroupSelectLabel from 'components/GroupSelectLabel';
import { Download, Lock, Locked, Save, VpnKey } from 'components/Icons';
import { downloadPlan, fetchDistanceMatrices, postPlanAction } from 'delivery/services/planService';
import { saveAs } from 'file-saver';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import { ga, useGDispatch } from 'state/store';
import { planEdits as pe, planStates as ps } from 'utils/constants';
import { useAPI, useSettings } from 'utils/customHooks';
import fetch from 'utils/fetch';
import { byId, isVal } from 'utils/utils';
import { actionTypes } from './planningReducer';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  button: {
    // minWidth: 100,
  },
  buttonWrapper: {
    marginBottom: theme.spacing(1),
    position: 'relative',
  },
  editInfo: {
    paddingRight: theme.spacing(1),
    ...theme.typography.button,
  },
  editInfoS: {
    paddingRight: theme.spacing(1),
    ...theme.typography.button,
    color: theme.palette.text.secondary,
  },
  frozenButton: {
    backgroundColor: `${green[100]} !important`,
    // '&:hover': {
    //   backgroundColor: green[700],
    // },
  },
  bottomRow: {
    paddingTop: '0px !important',
    marginBottom: theme.spacing(1),
  },
  topRow: {
    paddingBottom: '4px !important',
  },
  select: {
    width: 120,
  },
  step: {
    paddingLeft: theme.spacing(1),
    paddingRight: 0,
    textAlign: 'center',
  },
  checkbox: {
    paddingTop: 4,
    paddingBottom: 16,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  undoRedo: {
    flexGrow: 1,
  },
  badge: {
    top: -5,
    right: '50%',
    color: theme.palette.text.secondary,
  },
  originalPlanViewButton: {
    backgroundColor: red[500] + ' !important',
  },
  iconButton: {},
  frozenByChip: {
    margin: theme.spacing(1),
    float: 'right',
  },
  stepper: {
    minWidth: 250,
    paddingTop: 0,
    paddingRight: 0,
  },
  highlight: {
    '& input': {
      fontSize: '14px',
      color: theme.palette.text.secondary,
      textAlign: 'center',
    },
  },
  highlightAutocomplete: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const getRetailersGroup = (retailers, picklists, selectedPicklists) => {
  return Object.values(retailers).reduce((acc, { id, code, index }) => {
    if (!(index || index === 0)) index = Object.values(picklists).length;
    // else index;

    if (acc[index]) {
      acc[index].options.push({ value: id, label: code });
    } else if (selectedPicklists.includes(index) || !picklists[index]) {
      acc[index] = {
        label: picklists[index] ? picklists[index].name : 'UNASSIGNED',
        options: [{ value: id, label: code }],
      };
    }
    return acc;
  }, []);
};

const onResponse = (action) => () => {
  action();
};

const onError = (action) => () => {
  action();
};

export default function PlanningActionPanel({
  id,
  retailers,
  picklists,
  state: {
    date,
    branch,
    updatedAt,
    profileName,
    edits,
    frozenBy,
    past,
    editInfo,
    editInfoOverwrite,
    future,
    detailsLoading,
    routesView,
    salesView,
    originalPlanView,
    selectedPicklists,
    status,
    channels,
    salesmen,
    // selectedChannels,
  },
  dispatch,
  refetchPlanDetails,
  stats,
}) {
  const classes = useStyles();
  const [saveAction, , saveLoading] = useAPI(null, true);
  const [freezeAction, , freezeLoading] = useAPI(null, true);
  const [unfreezeAction, , unfreezeLoading] = useAPI(null, true);
  const settings = useSettings();
  const isUnfrozen = status !== ps.FROZEN;
  const [downloadAction] = useAPI();
  const gDispatch = useGDispatch();
  const dialog = useRef();

  const [highlightFiltersLocal, setHighlightFiltersLocal] = useState([]);

  const isFrozen = status === ps.FROZEN;

  const handleViewChange = (event, views) => {
    dispatch({
      type: actionTypes.UPDATE_VIEW_TYPE,
      views,
    });
  };

  const download = () => {
    downloadAction(downloadPlan(id), (resp) => {
      saveAs(resp, `Plan_${branch.name}_${profileName}_${date}.xlsx`);
    });
  };

  const optimize = async () => {
    try {
      const resp = await Promise.all(
        Object.values(picklists).map((pl) => fetch(fetchDistanceMatrices(id, pl.retailerIds)))
      );
      dispatch({ type: pe.REARRANGE_ROUTE_FULL, distanceMatrix: resp });
    } catch (err) {
      console.log(err); // eslint-disable-line
    }
  };

  const save = () =>
    saveAction(
      postPlanAction(id, 'save', updatedAt, edits),
      onResponse(refetchPlanDetails),
      onError(refetchPlanDetails)
    );

  const freeze = () => {
    freezeAction(
      postPlanAction(id, 'freeze', updatedAt, edits),
      (resp) => {
        gDispatch([ga.PLAN_FREEZED]);
        // updatePlanFreeze();
        onResponse(refetchPlanDetails)(resp);
      },
      onError(refetchPlanDetails)
    );
  };
  const unfreeze = () => {
    unfreezeAction(
      postPlanAction(id, 'unfreeze', updatedAt, edits),
      (resp) => {
        gDispatch([ga.PLAN_UNFREEZED]);
        // updatePlanFreeze();
        onResponse(refetchPlanDetails)(resp);
      },
      onError(refetchPlanDetails)
    );
  };

  const searchRetailers = useMemo(
    () => getRetailersGroup(retailers, picklists, selectedPicklists),
    [retailers, picklists, selectedPicklists]
  );

  const highlightOptions = useMemo(() => {
    const channelsOptions = channels
      .filter((c) => c)
      .map((channel) => ({
        value: channel,
        label: channel,
        group: 'Channels',
      }));

    const salesmenOptions = salesmen
      ? salesmen.map((salesman) => ({
          value: salesman.id,
          label: salesman.code,
          group: 'DSEs',
        }))
      : [];

    const customFields = [
      {
        value: 'orderOffBeat',
        label: 'Off Beat',
        group: 'Other Attribute',
      },
      {
        value: 'retry',
        label: 'Retries',
        group: 'Other Attribute',
      },
    ];
    return [...customFields, ...channelsOptions, ...salesmenOptions];
  }, [channels, salesmen]);

  useEffect(() => {
    const preSelected = highlightOptions
      .filter((opt) => opt.group === 'Channels')
      .filter((channel) => channel.label && channel.label.toLowerCase().includes('large'));
    setHighlightFiltersLocal(preSelected);
  }, [highlightOptions]);

  const renderEditInfo = () => {
    const getName = (index) => (isVal(index) ? byId(picklists, index, 'index')?.name : 'UA');

    if (typeof editInfo === 'string') {
      return (
        <Typography className={classNames(classes.step, classes.editInfo)}>
          {editInfoOverwrite || editInfo}
        </Typography>
      );
    }
    if (!editInfo || !editInfo.length)
      return (
        <Typography className={classNames(classes.step, classes.editInfo)}>
          {editInfoOverwrite || '--'}
        </Typography>
      );
    return (
      <Grid
        container
        className={classes.step}
        direction="row"
        justify="center"
        alignItems="baseline"
      >
        <Typography className={classes.editInfo}>{editInfo[0]}</Typography>
        <Typography className={classes.editInfoS}>{' : '}</Typography>
        <Typography className={classes.editInfo}>{getName(editInfo[1])}</Typography>
        <Typography className={classes.editInfoS}>{' => '}</Typography>
        <Typography className={classes.editInfo}>{getName(editInfo[2])}</Typography>
      </Grid>
    );
  };

  const views = [];
  if (salesView) views.push('sales');
  if (routesView) views.push('routes');
  if (originalPlanView) views.push('originalPlanView');

  return (
    <>
      <ConfirmationDialog
        title="Freeze?"
        desc="There are some Unassigned Customers"
        yes="Freeze Anyways"
        no="Cancel"
        ref={dialog}
        onYes={freeze}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.topRow}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <ToggleButtonGroup value={views} onChange={handleViewChange} size="small">
                <ToggleButton value="routes">Routes</ToggleButton>
                <ToggleButton value="sales">Sales</ToggleButton>
                {/* {status === ps.FROZEN && (
                <ToggleButton
                  value="originalPlanView"
                  classes={{ selected: classes.originalPlanViewButton }}
                >
                  O
                </ToggleButton>
              )} */}
              </ToggleButtonGroup>
            </Grid>
            <Grid item className={classes.undoRedo}>
              {!isFrozen ? (
                <div>
                  {renderEditInfo()}
                  <MobileStepper
                    variant={isFrozen ? 'dots' : 'progress'}
                    steps={past.length + future.length + 1}
                    position="static"
                    activeStep={past.length}
                    className={classes.stepper}
                    backButton={
                      <Badge
                        color="default"
                        badgeContent={past.length}
                        classes={{ badge: classes.badge }}
                      >
                        <Button
                          size="small"
                          onClick={() => {
                            dispatch({ type: actionTypes.UNDO });
                          }}
                          disabled={!past.length || originalPlanView}
                        >
                          <KeyboardArrowLeft />
                          UNDO
                        </Button>
                      </Badge>
                    }
                    nextButton={
                      <Badge
                        color="default"
                        badgeContent={future.length}
                        classes={{ badge: classes.badge }}
                      >
                        <Button
                          size="small"
                          onClick={() => {
                            dispatch({ type: actionTypes.REDO });
                          }}
                          disabled={!future.length || originalPlanView}
                        >
                          REDO
                          <KeyboardArrowRight />
                        </Button>
                      </Badge>
                    }
                  />
                </div>
              ) : (
                <Chip label={frozenBy ? frozenBy.name : '--'} className={classes.frozenByChip} />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.bottomRow}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Select
              className={classes.select}
              placeholder="customer"
              isSearchable
              isClearable
              formatGroupLabel={GroupSelectLabel}
              onChange={(select) => {
                dispatch({
                  type: actionTypes.OPEN_RETAILER_INFO_WINDOW,
                  retailerId: select ? select.value : null,
                });
              }}
              options={searchRetailers}
            />
            <Autocomplete
              className={classes.highlightAutocomplete}
              multiple
              size="small"
              disableCloseOnSelect
              options={highlightOptions}
              getOptionLabel={(option) => option.label}
              groupBy={(option) => option.group}
              value={highlightFiltersLocal}
              renderOption={(option, { selected }) => (
                <Box>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 4 }}
                    checked={selected}
                  />
                  <Typography variant="caption">{option.label}</Typography>
                </Box>
              )}
              renderInput={(params) => {
                if (params.InputProps.startAdornment) {
                  params.inputProps.value = params.InputProps.startAdornment.length + ' Selected';
                  params.InputProps.startAdornment = [];
                }
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    className={classes.highlight}
                    placeholder="DSEs, Channels"
                  />
                );
              }}
              style={{ width: 200 }}
              onChange={(_, values) => {
                setHighlightFiltersLocal(values);
                dispatch({
                  type: actionTypes.HIGHLIGHTS,
                  data: values.reduce(
                    (acc, val) => {
                      if (!acc[val.group]) acc[val.group] = [];
                      acc[val.group].push(val.value);
                      return acc;
                    },
                    { 'Other Attribute': [], Channels: [], DSEs: [] }
                  ),
                });
              }}
            />
            <div className={classes.buttonWrapper}>
              <Tooltip title="Optimize" placement="bottom">
                <CButton
                  className={classNames(classes.button)}
                  color="primary"
                  onClick={optimize}
                  disabled={originalPlanView}
                >
                  <Optimize />
                </CButton>
              </Tooltip>
            </div>
            <div className={classes.buttonWrapper}>
              <Tooltip title="Save" placement="bottom">
                <CButton
                  className={classNames(classes.button)}
                  onClick={save}
                  loading={saveLoading || detailsLoading}
                  disabled={originalPlanView || saveLoading || detailsLoading}
                  variant="outlined"
                  color="primary"
                >
                  <Save />
                </CButton>
              </Tooltip>
            </div>
            <div className={classes.buttonWrapper}>
              <Tooltip title="Download" placement="bottom">
                <CButton
                  className={classNames(classes.button)}
                  disabled={originalPlanView}
                  onClick={download}
                  color="primary"
                >
                  <Download />
                </CButton>
              </Tooltip>
            </div>
            <div className={classes.buttonWrapper}>
              <Tooltip title={isFrozen ? 'Frozen' : 'Freeze'} placement="bottom">
                <span>
                  <CButton
                    className={classNames(classes.button, {
                      [classes.frozenButton]: isFrozen,
                    })}
                    onClick={() => {
                      if (stats.unAssigned > 0) {
                        dialog.current.openDialog();
                      } else if (status !== ps.FROZEN) freeze();
                    }}
                    disabled={isFrozen || freezeLoading || detailsLoading || originalPlanView}
                    loading={freezeLoading || detailsLoading}
                    color="primary"
                  >
                    {isFrozen ? <Locked /> : <Lock />}
                  </CButton>
                </span>
              </Tooltip>
            </div>
            {settings?.tms.allowUnfreezingPlan ? (
              <div className={classes.buttonWrapper}>
                <Tooltip title={isUnfrozen ? 'UNFROZEN' : 'Unfreeze'} placement="bottom">
                  <span>
                    <CButton
                      className={classNames(classes.button, {
                        [classes.frozenButton]: isUnfrozen,
                      })}
                      onClick={() => {
                        if (status !== ps.UNFREEZE) unfreeze();
                      }}
                      disabled={isUnfrozen || unfreezeLoading || detailsLoading || originalPlanView}
                      loading={unfreezeLoading || detailsLoading}
                      color="primary"
                    >
                      {isUnfrozen ? null : <VpnKey />}
                    </CButton>
                  </span>
                </Tooltip>
              </div>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
